import { captureException } from './sentry';
import { showDangerSnackbar, showSuccessSnackbar } from './show-snackbar';

export interface SafeAsyncOptions<T> {
  onSuccess?: (res: T) => any;
  onError?: (e: Error, errors?: string) => any;
  onComplete?: () => any;
  successCondition: boolean | ((res: T) => boolean);
  errorMessage?: (errors?: string) => string | string;
  successMessage?: (res: T) => string | string;
}

export interface QueryData {
  [key: string]: {
    errors?: {
      message: string;
    }[];
    [key: string]: unknown;
  };
}

export const safeAsync = async <T = any>(
  asyncFn: () => Promise<T>,
  options: SafeAsyncOptions<T> = {
    successCondition: true,
  },
) => {
  let errors;
  try {
    const res = (await asyncFn()) as any;
    if (
      typeof options.successCondition === 'function'
        ? !options.successCondition(res)
        : !options.successCondition
    ) {
      if (res.data != null) {
        const firstKey = Object.keys(res.data)[0];
        if (firstKey != null) {
          const data = res.data[firstKey];
          const errorList = data.errors ?? [];
          errors = (errorList?.length ?? 0) > 0 ? errorList[0].message : undefined;
          throw new Error(
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `Response returned errors: ${errorList.map((it: any) => it.message).join('. ')}`,
          );
        }
      }
      throw new Error(`No response data. Response => ${JSON.stringify(res)}`);
    } else {
      if (options.onSuccess != null) {
        options.onSuccess(res);
      }
      if (options.successMessage) {
        showSuccessSnackbar(
          typeof options.successMessage === 'function'
            ? options.successMessage(res)
            : options.successMessage,
        );
      }
    }
  } catch (e) {
    captureException(e);
    if (options.onError != null) {
      options.onError(e as Error, errors);
    }
    if (options.errorMessage) {
      showDangerSnackbar(
        typeof options.errorMessage === 'function'
          ? options.errorMessage(errors)
          : options.errorMessage,
      );
    }
  } finally {
    if (options.onComplete) {
      options.onComplete();
    }
  }
};
