import { FC } from 'react';
import styled from '@emotion/styled';

const CoffeeLiquid = styled.div`
  position: relative;
  background: transparent;
  border: 0.25rem solid ${props => props.theme.palette.secondary.main};
  height: 5rem;
  width: 6rem;
  outline: 0;
  overflow: hidden; /* Set to none to see fill square outside box */
  border-radius: 0 0 50% 50%;
  border-top: none;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  &::after {
    content: '';
    position: absolute;
    bottom: -50%; /* Center sauare */
    left: -75%; /* Center sauare */
    height: 250%;
    width: 250%;
    background-color: rgba(111, 78, 55, 0.75);
    border-radius: 35%; /* Smooth edges to appear like liquid */
    animation: spin 6s ease-in-out infinite; /* Set to forwards to freeze on last frame */
  }

  @keyframes spin {
    0% {
      transform: translateY(90%) rotate(90deg);
    }
    50% {
      transform: translateY(50%) rotate(450deg);
    }
    100% {
      transform: translateY(90%) rotate(810deg);
    }
  }
`;

const CoffeeSpinnerContainer = styled.div`
  position: relative;
`;

const CoffeeMugHandler = styled.div`
  position: absolute;
  overflow: hidden;
  width: 2rem;
  height: 3rem;
  right: -1.75rem;
  top: 15%;
  &::before {
    content: '';
    position: absolute;
    width: 2rem;
    height: 2rem;
    border: 0.25rem solid ${props => props.theme.palette.secondary.main};
    border-radius: 50%;
    left: -1rem;
  }
`;

export const CoffeeSpinner: FC = () => (
  <CoffeeSpinnerContainer data-e2e="coffe-spinner">
    <CoffeeMugHandler />
    <CoffeeLiquid />
  </CoffeeSpinnerContainer>
);
