import { MenuRoute } from '@blb-ventures/react-components';
import styled from '@emotion/styled';
import {
  Divider,
  Icon,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListProps,
} from '@mui/material';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC, MouseEvent, useMemo } from 'react';
import { ScrollspyProps } from 'react-scrollspy';

const StyledLinkListItem = styled(ListItemButton)`
  &:hover {
    background-color: ${props => `${props.theme.palette.primary.main}36`};
  }
  &.menu-active {
    path {
      fill: ${props => props.theme.palette.primary.main} !important;
    }
    span {
      color: ${props => props.theme.palette.primary.main};
    }
    background-color: ${props => `${props.theme.palette.primary.main}26`};
    &:hover {
      background-color: ${props => `${props.theme.palette.primary.main}36`};
    }
  }
`;

const StyledDivider = styled(Divider)`
  margin: ${props => props.theme.spacing(1, 0)};
`;

export interface SmartMenuProps extends ListProps {
  hasDivider?: boolean;
  scrollSpy?: Partial<ScrollspyProps>;
  menuItems: MenuRoute[];
  rounded?: boolean;
  itemsOnly?: boolean;
  afterClick?: () => any;
}

const ScrollSpy = dynamic<any>(() => import('react-scrollspy'), { ssr: true });

const handleScrollToEl =
  (elId: string, onClick?: (e: MouseEvent) => void, afterClick?: (e: MouseEvent) => void) =>
  (e: MouseEvent) => {
    if (onClick) {
      onClick(e);
    }
    const el = document.getElementById(elId);
    if (el != null) {
      window.scrollTo({ behavior: 'smooth', top: el.offsetTop - 80 });
    }
    if (afterClick) {
      afterClick(e);
    }
  };

export const SmartMenu: FC<SmartMenuProps> = ({
  hasDivider,
  menuItems,
  scrollSpy,
  rounded = false,
  itemsOnly,
  afterClick,
  ...props
}) => {
  const { asPath } = useRouter();
  const activeRoute = useMemo(
    () =>
      menuItems.findIndex(
        it =>
          it.url === asPath ||
          (it.startsWith
            ? asPath.startsWith(
                it.url != null ? (typeof it.url === 'string' ? it.url : it.url.pathname || '') : '',
              )
            : false),
      ),
    [menuItems, asPath],
  );
  const ListComponent = scrollSpy != null ? ScrollSpy : List;

  const listComponentProps = useMemo(
    () =>
      scrollSpy != null
        ? { items: menuItems.map(it => it.scrollToEl), ...scrollSpy }
        : { disablePadding: true },
    [menuItems, scrollSpy],
  );
  const menu = useMemo(
    () =>
      menuItems.map((it, idx) => {
        if (it.divider) {
          // eslint-disable-next-line react/no-array-index-key
          return <StyledDivider key={idx} />;
        }
        const content = (
          <StyledLinkListItem
            onClick={
              it.scrollToEl ? handleScrollToEl(it.scrollToEl, it.onClick, afterClick) : it.onClick
            }
            className={it.active || idx === activeRoute ? 'menu-active' : undefined}
            data-rounded={rounded ? 1 : 0}
            disabled={it.disabled}
            data-e2e="smart-menu-list-item"
            key={idx}
          >
            {it.icon != null && <ListItemIcon>{it.icon}</ListItemIcon>}
            {it.label != null && (
              <ListItemText
                primary={it.label}
                primaryTypographyProps={it.labelProps as any}
                secondary={it.subtitle}
              />
            )}
            {it.endIcon?.icon != null && (
              <ListItemSecondaryAction>
                {it.endIcon.onClick ? (
                  // eslint-disable-next-line react/jsx-handler-names
                  <IconButton onClick={it.endIcon.onClick} size="small">
                    {it.endIcon.icon}
                  </IconButton>
                ) : (
                  <Icon>{it.endIcon.icon}</Icon>
                )}
              </ListItemSecondaryAction>
            )}
          </StyledLinkListItem>
        );
        const linkedContent =
          it.url != null ? (
            // eslint-disable-next-line react/no-array-index-key
            <NextLink key={idx} href={it.url} passHref>
              <Link underline="none" color="inherit" target={it.external ? '_blank' : undefined}>
                {content}
              </Link>
            </NextLink>
          ) : (
            content
          );
        return [hasDivider && idx > 0 && <Divider />, linkedContent];
      }),
    [menuItems, rounded, afterClick, activeRoute, hasDivider],
  );
  return itemsOnly ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{menu}</>
  ) : (
    <ListComponent {...listComponentProps} {...props}>
      {menu}
    </ListComponent>
  );
};
