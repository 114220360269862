import * as Types from '../../types';

import { gql } from '@apollo/client';
export type MutationErrorFieldsFragment = { field?: string | null; message?: string | null };

export const MutationErrorFieldsFragmentDoc = gql`
  fragment mutationErrorFields on MutationErrorType {
    field
    message
  }
`;
