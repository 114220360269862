import { MenuRoute } from '@blb-ventures/react-components';
import styled from '@emotion/styled';
import { BottomNavigation, BottomNavigationAction, List } from '@mui/material';
import { SmartMenu } from '@web/common/components/navigation/smart-menu';
import { useRouter } from 'next/router';
import { FC, useMemo, useState } from 'react';
import { SafeAreaDrawer } from '../safe-area-drawer';

const StyledBottomNavigation = styled(BottomNavigation)`
  background-color: white;
  width: 100%;
  box-shadow: none;
  height: calc(${props => props.theme.spacing(8)} + env(safe-area-inset-bottom, 0));
  &[data-has-shadow='1'] {
    box-shadow: 0px -2px 4px -1px rgba(0, 0, 0, 0.2), 0px -4px 5px 0px rgba(0, 0, 0, 0.14),
      0px -1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }

  & > button,
  & > a {
    padding-bottom: env(safe-area-inset-bottom, 0);
    min-width: 60px;
    line-height: 1.5;
    picture {
      font-size: 0;
    }
  }
`;

const StyledList = styled(List)`
  padding-bottom: env(safe-area-inset-bottom, 0);
`;

const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  border-top: 3px solid transparent;
  &.Mui-selected {
    border-top-color: ${props => props.theme.palette.primary.main};
  }
  &.Mui-selected path {
    fill: ${props => props.theme.palette.primary.main} !important;
  }
`;

interface BottomNavProps {
  showLabel?: boolean;
  hasShadow?: boolean;
  routes: MenuRoute[];
}

export const BottomNav: FC<BottomNavProps> = ({ showLabel, hasShadow = true, routes }) => {
  const { asPath, push } = useRouter();
  // States
  const [selectedSubRoutes, setSelectedSubRoutes] = useState<MenuRoute[] | undefined>();
  // Memos
  const activeRoute = useMemo(
    () =>
      routes.findIndex(
        it =>
          it.url === asPath.replace('#', '') ||
          (it.startsWith
            ? asPath
                .replace('#', '')
                .startsWith(
                  it.url != null
                    ? typeof it.url === 'string'
                      ? it.url
                      : it.url.pathname || ''
                    : '',
                )
            : false),
      ),
    [routes, asPath],
  );
  // Handlers
  const handleRouteChange = (_: any, newRoute: number) => {
    const route = routes[newRoute];
    if (route.subRoutes != null) {
      setSelectedSubRoutes(route.subRoutes);
    } else if (!route.external && route.url != null) {
      if (route.url.toString().startsWith('http')) {
        window.location = (route.url || '') as any;
      } else {
        push(route.url || '');
      }
    }
  };

  return (
    <>
      <StyledBottomNavigation
        data-e2e="bottom-navigation"
        value={activeRoute}
        onChange={handleRouteChange}
        data-has-shadow={hasShadow ? '1' : 0}
      >
        {routes.map((it, idx) => (
          <StyledBottomNavigationAction
            key={it.label ?? it.url?.toString() ?? idx.toString()}
            icon={it.icon}
            label={showLabel ? it.label : undefined}
            // eslint-disable-next-line react/jsx-handler-names
            onClick={it.onClick}
            showLabel
            data-e2e="button-navigation"
            // Workaround to open in a new tab for external urls since bottom navigation action is actually a button but does not expose the button props
            {...{
              href: it.external ? it.url?.toString() ?? '' : undefined,
              target: it.external ? '_blank' : undefined,
              rel: 'noopener noreferrer',
            }}
          />
        ))}
      </StyledBottomNavigation>
      <SafeAreaDrawer
        open={selectedSubRoutes != null}
        anchor="bottom"
        onClose={() => setSelectedSubRoutes(undefined)}
      >
        <StyledList>
          <SmartMenu menuItems={selectedSubRoutes || []} />
        </StyledList>
      </SafeAreaDrawer>
    </>
  );
};
