import * as Types from '../../types';

import { gql } from '@apollo/client';
import { SubscriptionFieldsFragmentDoc } from './subscription.fragment';
export type UserFieldsFragment = {
  id: string;
  isStaff: boolean;
  username: string;
  fullName?: string | null;
  email?: string | null;
  doc?: string | null;
  phone?: string | null;
  birthday?: Date | null;
  gender?: Types.UserGender | null;
  mgmCode: string;
  isSubscriber: boolean;
  kind?: Types.PersonKind | null;
  fancyName?: string | null;
  cityRegistration?: string | null;
  stateRegistration?: string | null;
  isLocalDeliverer?: boolean | null;
  avatar?: { url: string; alt?: string | null } | null;
  currentSubscription?: {
    id: string;
    subtotal: number;
    status?: Types.SubscriptionStatus | null;
    total: number;
    nextCycle: Date;
    nextCycleChange?: Date | null;
    nextCharge?: Date | null;
    nextDate?: Date | null;
    freightDays: number;
    freight: number;
    freightKind?: Types.ShippingKind | null;
    createdAt: Date;
    updatedAt: Date;
    kind?: Types.SubscriptionKind | null;
    discount: number;
    cancelOnNextCycle: boolean;
    pendingInvoices: Array<{
      id: string;
      status: Types.InvoiceStatus;
      chargeInfo?: {
        paymentMethodKind?: Types.PaymentMethod | null;
        invoicePdf?: string | null;
        bankSlipBarcode?: string | null;
        pixQrcode?: string | null;
      } | null;
    }>;
    items: Array<{
      id: string;
      price: number;
      quantity: number;
      discount: number;
      subtotal: number;
      total: number;
      product: {
        id: string;
        name: string;
        quantityStr?: string | null;
        originalPrice?: number | null;
        price: number;
        kind: Types.ProductKind;
        coffeeKind?: Types.ProductCoffeeKind | null;
        images: Array<{ id: string; file?: { url: string; alt?: string | null } | null }>;
      };
      gridOptionValue?: { id: string; name: string; option: { id: string; name: string } } | null;
      productGrid?: {
        stock: number;
        value: { id: string; name: string; option: { id: string; name: string } };
        image?: { url: string; alt?: string | null } | null;
      } | null;
    }>;
  } | null;
};

export const UserFieldsFragmentDoc = gql`
  fragment userFields on UserType {
    id
    isStaff
    username
    fullName
    email
    doc
    phone
    birthday
    gender
    mgmCode
    isSubscriber
    kind
    fancyName
    cityRegistration
    stateRegistration
    isLocalDeliverer
    avatar {
      url(size: "256x256")
      alt
    }
    currentSubscription {
      ...subscriptionFields
      pendingInvoices {
        id
        status
        chargeInfo {
          paymentMethodKind
          invoicePdf
          bankSlipBarcode
          pixQrcode
        }
      }
    }
  }
  ${SubscriptionFieldsFragmentDoc}
`;
