import * as Types from '../../types';

import { gql } from '@apollo/client';
export type SubscriptionItemFieldsFragment = {
  id: string;
  price: number;
  quantity: number;
  discount: number;
  subtotal: number;
  total: number;
  product: {
    id: string;
    name: string;
    quantityStr?: string | null;
    originalPrice?: number | null;
    price: number;
    kind: Types.ProductKind;
    coffeeKind?: Types.ProductCoffeeKind | null;
    images: Array<{ id: string; file?: { url: string; alt?: string | null } | null }>;
  };
  gridOptionValue?: { id: string; name: string; option: { id: string; name: string } } | null;
  productGrid?: {
    stock: number;
    value: { id: string; name: string; option: { id: string; name: string } };
    image?: { url: string; alt?: string | null } | null;
  } | null;
};

export const SubscriptionItemFieldsFragmentDoc = gql`
  fragment subscriptionItemFields on SubscriptionItemType {
    id
    price
    quantity
    product {
      id
      name
      quantityStr
      originalPrice
      price
      kind
      coffeeKind
      images {
        id
        file {
          url
          alt
        }
      }
    }
    discount
    subtotal
    total
    gridOptionValue {
      id
      option {
        id
        name
      }
      name
    }
    productGrid {
      value {
        id
        name
        option {
          id
          name
        }
      }
      image {
        url
        alt
      }
      stock
    }
  }
`;
