import * as Types from '../types';

import { gql } from '@apollo/client';
import { UserFieldsFragmentDoc } from '../fragments/_gen/user.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MeQuery = {
  me?: {
    id: string;
    isStaff: boolean;
    username: string;
    fullName?: string | null;
    email?: string | null;
    doc?: string | null;
    phone?: string | null;
    birthday?: Date | null;
    gender?: Types.UserGender | null;
    mgmCode: string;
    isSubscriber: boolean;
    kind?: Types.PersonKind | null;
    fancyName?: string | null;
    cityRegistration?: string | null;
    stateRegistration?: string | null;
    isLocalDeliverer?: boolean | null;
    avatar?: { url: string; alt?: string | null } | null;
    currentSubscription?: {
      id: string;
      subtotal: number;
      status?: Types.SubscriptionStatus | null;
      total: number;
      nextCycle: Date;
      nextCycleChange?: Date | null;
      nextCharge?: Date | null;
      nextDate?: Date | null;
      freightDays: number;
      freight: number;
      freightKind?: Types.ShippingKind | null;
      createdAt: Date;
      updatedAt: Date;
      kind?: Types.SubscriptionKind | null;
      discount: number;
      cancelOnNextCycle: boolean;
      pendingInvoices: Array<{
        id: string;
        status: Types.InvoiceStatus;
        chargeInfo?: {
          paymentMethodKind?: Types.PaymentMethod | null;
          invoicePdf?: string | null;
          bankSlipBarcode?: string | null;
          pixQrcode?: string | null;
        } | null;
      }>;
      items: Array<{
        id: string;
        price: number;
        quantity: number;
        discount: number;
        subtotal: number;
        total: number;
        product: {
          id: string;
          name: string;
          quantityStr?: string | null;
          originalPrice?: number | null;
          price: number;
          kind: Types.ProductKind;
          coffeeKind?: Types.ProductCoffeeKind | null;
          images: Array<{ id: string; file?: { url: string; alt?: string | null } | null }>;
        };
        gridOptionValue?: { id: string; name: string; option: { id: string; name: string } } | null;
        productGrid?: {
          stock: number;
          value: { id: string; name: string; option: { id: string; name: string } };
          image?: { url: string; alt?: string | null } | null;
        } | null;
      }>;
    } | null;
  } | null;
};

export const MeDocument = gql`
  query Me {
    me {
      ...userFields
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
