import styled from '@emotion/styled';
import { OptimizedImage } from '@web/common/components/optimized-image';
import { ButtonBase, Paper, Typography } from '@mui/material';
import { SmartImage } from '../smart-image';

export const ClubTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing(2)};
`;

export const AdminPaper = styled(Paper)`
  margin-bottom: ${props => props.theme.spacing(3)};
`;

export const ImageItem = styled(OptimizedImage)<{
  hasBorderRadius?: boolean;
  width?: string;
  height?: string;
}>`
  img {
    width: ${props => `${props.width ?? '56'}px`};
    height: ${props => `${props.height ?? '56'}px`};
    object-position: center;
    object-fit: cover;
    ${props => props.hasBorderRadius && `border-radius: ${props.theme.shape.borderRadius}px`}
  }
`;

export const ProfileImage = styled(OptimizedImage)<{ width?: string; height?: string }>`
  img {
    width: ${props => `${props.width ?? 40}px`};
    height: ${props => `${props.height ?? 40}px`};
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
  }
`;

export const WhiteText = styled(Typography)<{ isLight?: boolean }>`
  color: ${props => (props.isLight ? 'rgba(255, 255, 255, 0.8)' : '#FFF')};
`;

export const CenteredRoundedImage = styled(SmartImage)`
  img {
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
  }
`;

export const StyledBorderedButton = styled(ButtonBase)`
  color: ${props => props.theme.palette.text.primary};
  border-radius: ${props => props.theme.shape.borderRadius}px;
  width: 100%;
  text-decoration: none;
`;
