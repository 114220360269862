import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { CoffeeSpinner } from './coffee-spinner';

const LoaderContainer = styled.div<{ position?: string }>`
  position: ${props => props.position ?? 'fixed'};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  color: white;
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.5);
`;

interface OwnProps {
  position?:
    | 'fixed'
    | 'absolute'
    | 'relative'
    | 'inherit'
    | 'initial'
    | 'sticky'
    | 'static'
    | 'unset';
  loading?: boolean;
  loadingMessage?: string;
}

export const Loader: FC<OwnProps> = ({ position, loadingMessage, loading }) =>
  loading != null && loading === true ? (
    <LoaderContainer position={position} data-e2e="loader">
      <CoffeeSpinner />
      {loadingMessage && (
        <Box mt={2} px={2} textAlign="center">
          <Typography variant="subtitle2">{loadingMessage}</Typography>
        </Box>
      )}
    </LoaderContainer>
  ) : null;
