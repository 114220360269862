import * as Types from '../../types';

import { gql } from '@apollo/client';
import { SubscriptionItemFieldsFragmentDoc } from './subscription-item.fragment';
export type SubscriptionFieldsFragment = {
  id: string;
  subtotal: number;
  status?: Types.SubscriptionStatus | null;
  total: number;
  nextCycle: Date;
  nextCycleChange?: Date | null;
  nextCharge?: Date | null;
  nextDate?: Date | null;
  freightDays: number;
  freight: number;
  freightKind?: Types.ShippingKind | null;
  createdAt: Date;
  updatedAt: Date;
  kind?: Types.SubscriptionKind | null;
  discount: number;
  cancelOnNextCycle: boolean;
  items: Array<{
    id: string;
    price: number;
    quantity: number;
    discount: number;
    subtotal: number;
    total: number;
    product: {
      id: string;
      name: string;
      quantityStr?: string | null;
      originalPrice?: number | null;
      price: number;
      kind: Types.ProductKind;
      coffeeKind?: Types.ProductCoffeeKind | null;
      images: Array<{ id: string; file?: { url: string; alt?: string | null } | null }>;
    };
    gridOptionValue?: { id: string; name: string; option: { id: string; name: string } } | null;
    productGrid?: {
      stock: number;
      value: { id: string; name: string; option: { id: string; name: string } };
      image?: { url: string; alt?: string | null } | null;
    } | null;
  }>;
};

export const SubscriptionFieldsFragmentDoc = gql`
  fragment subscriptionFields on SubscriptionType {
    id
    subtotal
    status
    total
    nextCycle
    nextCycleChange
    nextCharge
    nextDate
    freightDays
    freight
    freightKind
    createdAt
    updatedAt
    kind
    items {
      ...subscriptionItemFields
    }
    discount
    cancelOnNextCycle
  }
  ${SubscriptionItemFieldsFragmentDoc}
`;
